<template>
  <div>
    <b-modal
        id="modal-edit-report"
        ref="modal-edit-report"
        footer-class="flex-nowrap"
        no-close-on-backdrop
        no-close-on-esc
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.editReport.title') }}</h3>
      </template>

      <template #default>
        <div class="mt-4 mx-5 mb-3">
          <b-form-input
              v-model="reportName"
              :placeholder="$t('modals.createReport.name')"
              @update="setName"
              autofocus
              debounce="500"
              @keyup.enter="editReport"
          >
          </b-form-input>
        </div>
        <div class="mx-5 mb-4">
          <b-form-input
              v-model="reportDescription"
              :placeholder=" $t('modals.createReport.description') "
              @update="setDescription"
              debounce="500"
              @keyup.enter="editReport"
          >
          </b-form-input>
        </div>
      </template>

      <template #modal-footer>
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="cancelModal"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            :disabled="isDisabled"
            variant="primary"
            class="p-3"
            block
            @click="editReport"
        >
          {{ $t('modals.editReport.change') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalEditReport',
  props: ['report', 'tableIndex'],
  data: () => ({
    reportName: null,
    reportDescription: null,
  }),
  created() {
    if (this.report) {
      this.reportName = this.report.name;
      this.reportDescription = this.report.description;
    }
  },
  mounted() {
    this.$refs['modal-edit-report'].show();
  },
  computed: {
    isReportNameSet() {
      return !!this.reportName;
    },
    isDisabled() {
      // if teamName is empty or undefined
      if (!this.reportName) {
        return true;
      }
      if (
        this.reportName === this.report.name
        && this.reportDescription === this.report.description
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    setName(value) {
      this.$emit('update-fields', 'teamName', value);
    },
    setDescription(value) {
      this.$emit('update-fields', 'teamDescription', value);
    },
    editReport() {
      if (!this.isDisabled) {
        this.$emit('edit-report', this.reportName, this.reportDescription);
      }
    },
    cancelModal() {
      this.$emit('cancel');
    },
  },
};
</script>

<style scoped>

</style>
